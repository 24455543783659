*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  background-color: #111A20;
}

.body-container{
  display: flex;
  width: 100%;
  /* justify-content: 'center'; */
  padding: 100px;
}

.left-container{
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  flex: 0.7;
  /* align-items: center; */
  z-index: 2;
}

.price-container{
  margin-top: 20px;
  display: flex;
  width: 300px;
  justify-content: space-between;
}

.left-container .price-container p{
  font-size: 30px;
}

.left-container .price-container button{
  padding: 20px;
  border-radius: '100%';
  font-size: 15px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  width: 170px;
  justify-content: center;
  
}

.left-container .price-container button:hover{
  background-color: #111A20;
  color: white;
}

.left-container p{
  color: white;
  font-size: 4em;
  font-weight: 600;
  /* background-color: black; */
}

.right-container{
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  flex: 0.5;
}

@media only screen and (max-width:700px)  {
  .body-container{
    display: flex;
    width: 100%;
    justify-content: 'center';
    align-items: center;
    padding: 100px;
    flex-direction: column-reverse;

  }

  img{
    width: 200px;
    padding-bottom: 20px;
  }

  .left-container p{
    font-size: large;
    text-align: center;
  }

  

}