.selection-container{
    display: flex;
    flex-direction: column;
}
.selection-container p{
    font-size: large;
    font-family: cursive;
}

.colors-container{
    display: flex;
    justify-content: space-between;
    width: 300px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
}

.outer-container{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid;
}

.inner-container-selected{
    /* padding: 10px; */
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #666666;
}


.inner-container{
    /* padding: 10px; */
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #666666;
}